import React from "react";
import Navbar from "../components/Navbar";
import {
  FaAward,
  FaHistory,
  FaLightbulb,
  FaStar,
  FaChalkboardTeacher,
  FaBuilding,
  FaHandsHelping,
  FaQuoteRight,
  FaUsers, // Add icons for new sections if needed
  FaHandshake,
  FaRegListAlt,
} from "react-icons/fa";

const AboutPage = () => {
  return (
    <div
      className="bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: "url('/path-to-your-school-background-image.jpg')",
      }}
    >
      <div className="bg-gray-900 bg-opacity-50 min-h-screen">
        <Navbar />
        <div className="max-w-7xl mx-auto mt-10 p-6">
          {/* Hero Image Section */}
          <div className="relative">
            <img
              src="/path-to-your-welcome-image.jpg"
              alt="Welcome to World Star Academy"
              className="w-full h-72 object-cover rounded-lg shadow-lg"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-transparent to-transparent"></div>
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="text-center text-white p-6">
                <h1 className="text-4xl font-bold mb-4">
                  <span className="text-indigo-400">Welcome to </span>
                  <span className="text-yellow-300">World </span>
                  <span className="text-green-400">Star </span>
                  <span className="text-red-400">Academy</span>
                </h1>
                <p className="text-lg">
                  <span className="text-pink-400">Empowering Minds, </span>
                  <span className="text-blue-300">Inspiring Dreams, </span>
                  <span className="text-orange-400">
                    Providing Quality Education,{" "}
                  </span>
                  <span className="text-green-300">and Shaping the Future</span>
                </p>
              </div>
            </div>
          </div>

          {/* Card Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 mt-10">
            {/* School Mission Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-indigo-100">
              <div className="flex items-center mb-4">
                <FaStar className="text-indigo-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Mission
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Our mission is to foster a nurturing and challenging environment
                that promotes academic excellence, personal growth, and social
                responsibility. We are committed to helping each student reach
                their full potential.
              </p>
            </div>

            {/* School Vision Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-yellow-100">
              <div className="flex items-center mb-4">
                <FaLightbulb className="text-yellow-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Vision
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                We envision a world where our students become leaders in their
                chosen fields, empowered with the knowledge, skills, and values
                necessary to make a positive impact on society.
              </p>
            </div>

            {/* School History Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-green-100">
              <div className="flex items-center mb-4">
                <FaHistory className="text-green-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our History
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Founded in 1980, our school has a rich history of providing
                high-quality education to students from diverse backgrounds.
                Over the years, we have grown and evolved, but our commitment to
                excellence remains unchanged.
              </p>
            </div>

            {/* Core Values Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-red-100">
              <div className="flex items-center mb-4">
                <FaAward className="text-red-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Core Values
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Integrity, Respect, Responsibility, and Excellence are the
                cornerstones of our community. These values guide our decisions,
                inspire our actions, and shape the character of our students.
              </p>
            </div>

            {/* Faculty Profiles Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-blue-100">
              <div className="flex items-center mb-4">
                <FaChalkboardTeacher className="text-blue-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Meet Our Faculty
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Our dedicated and experienced faculty are passionate about
                teaching and committed to inspiring students to achieve their
                best.
              </p>
            </div>

            {/* Campus Facilities Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-purple-100">
              <div className="flex items-center mb-4">
                <FaBuilding className="text-purple-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Campus Facilities
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                Our campus features state-of-the-art facilities, including
                science labs, a library, sports grounds, and creative arts
                studios.
              </p>
            </div>

            {/* Community Engagement Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-teal-100">
              <div className="flex items-center mb-4">
                <FaHandsHelping className="text-teal-500 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Community Engagement
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                At World Star Academy, we believe in giving back. Our students
                and staff actively participate in community service projects
                throughout the year.
              </p>
            </div>

            {/* Who We Are Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-teal-200">
              <div className="flex items-center mb-4">
                <FaUsers className="text-teal-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Who We Are
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                We are a community of dedicated educators, supportive staff, and
                enthusiastic students. Our faculty is committed to fostering a
                love of learning and encouraging intellectual curiosity, while
                our staff ensures a safe and supportive environment for all.
              </p>
            </div>

            {/* Our Promise Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-indigo-200">
              <div className="flex items-center mb-4">
                <FaHandshake className="text-indigo-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  Our Promise
                </h2>
              </div>
              <p className="text-gray-700 leading-relaxed">
                We promise to provide an education that not only focuses on
                academic excellence but also fosters character development and a
                global perspective. At World Star Academy, we are committed to
                helping each student realize their full potential and achieve
                their dreams.
              </p>
            </div>

            {/* What We Offer Card */}
            <div className="bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 hover:bg-yellow-200">
              <div className="flex items-center mb-4">
                <FaRegListAlt className="text-yellow-600 text-3xl mr-3" />
                <h2 className="text-2xl font-semibold text-gray-800">
                  What We Offer
                </h2>
              </div>
              <ul className="text-gray-700 list-disc list-inside leading-relaxed space-y-2">
                <li>
                  <span className="font-semibold">
                    Comprehensive Curriculum:
                  </span>{" "}
                  A balanced and rigorous academic program that challenges and
                  engages students.
                </li>
                <li>
                  <span className="font-semibold">
                    Extracurricular Activities:
                  </span>{" "}
                  A variety of clubs, sports, and arts programs that allow
                  students to explore their interests and talents.
                </li>
                <li>
                  <span className="font-semibold">Supportive Community:</span> A
                  caring and inclusive environment where every student feels
                  valued and supported.
                </li>
              </ul>
            </div>
          </div>

          {/* Testimonials Section */}
          <div className="bg-white shadow-lg rounded-lg p-8 mb-12">
            <div className="flex items-center mb-4">
              <FaQuoteRight className="text-indigo-600 text-3xl mr-3" />
              <h2 className="text-2xl font-semibold text-gray-800">
                What Our Community Says
              </h2>
            </div>
            <p className="text-gray-700 leading-relaxed italic mb-4">
              "World Star Academy has been an incredible place for my child's
              growth and development. The teachers are supportive, and the
              environment is enriching."
            </p>
            <p className="text-gray-700 leading-relaxed italic">
              "The diverse curriculum and extracurricular activities offered at
              World Star Academy have truly helped my son become a well-rounded
              individual."
            </p>
          </div>

          {/* Contact Information Section */}
          <div className="bg-gray-800 text-white rounded-lg p-8">
            <h2 className="text-2xl font-semibold mb-4">Contact Us</h2>
            <p className="leading-relaxed mb-2">
              <strong>Email:</strong> info@worldstaracademy.edu
            </p>
            <p className="leading-relaxed mb-2">
              <strong>Phone:</strong> (123) 456-7890
            </p>
            <p className="leading-relaxed">
              <strong>Address:</strong> 1234 Academy Lane, Education City, EC
              56789
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
