// src/components/Mission.js
import React from "react";
import { FaRocket, FaLightbulb } from "react-icons/fa";

const Mission = () => {
  return (
    <section
      id="mission"
      className="p-8 bg-gradient-to-r from-green-100 to-blue-100"
    >
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
          <span className="text-green-600">Our </span>
          <span className="text-blue-600">Mission</span>
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center mb-8">
          <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
            <FaRocket className="text-4xl text-green-500 mb-2" />
            <h3 className="text-xl font-semibold text-gray-800">
              Inspire & Empower
            </h3>
          </div>
          <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
            <FaLightbulb className="text-4xl text-yellow-500 mb-2" />
            <h3 className="text-xl font-semibold text-gray-800">
              Creativity & Discovery
            </h3>
          </div>
        </div>
        <p className="text-gray-700 mb-4 leading-relaxed">
          Our mission is to inspire and empower students to achieve their full
          potential. We strive to create a learning environment that promotes
          creativity, critical thinking, and a passion for discovery.
        </p>
        <p className="text-gray-700 mb-4 leading-relaxed">
          We are committed to nurturing responsible citizens who are prepared to
          make positive contributions to society.
        </p>
      </div>
    </section>
  );
};

export default Mission;
