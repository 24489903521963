import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer"; // Import your Footer component if needed

const services = [
  {
    title: "Academic Programs",
    description: "We offer a comprehensive range of academic programs from primary to secondary education, tailored to meet the diverse needs and interests of our students.",
    color: "bg-blue-100",
  },
  {
    title: "Extracurricular Activities",
    description: "Our school provides a variety of extracurricular activities, including sports, arts, and music, to help students explore their interests and develop new skills.",
    color: "bg-green-100",
  },
  {
    title: "Counseling Services",
    description: "We offer counseling services to support the mental and emotional well-being of our students, providing a safe space for them to express their feelings and seek guidance.",
    color: "bg-yellow-100",
  },
  {
    title: "Library",
    description: "Our library is a resource-rich environment where students can access a wide range of books, research materials, and digital resources to support their learning.",
    color: "bg-purple-100",
  },
  {
    title: "Sports Facilities",
    description: "Our campus features state-of-the-art sports facilities, including a gymnasium, swimming pool, and various outdoor sports courts for students to stay active and healthy.",
    color: "bg-red-100",
  },
  {
    title: "Arts and Music Studios",
    description: "We offer well-equipped arts and music studios where students can explore their creative talents and engage in artistic expression.",
    color: "bg-teal-100",
  },
];

const Services = () => {
  return (
    <div>
      <Navbar />
      <section id="services" className="bg-gray-50 min-h-screen py-12">
        <div className="max-w-7xl mx-auto px-6">
          <h1 className="text-4xl font-bold text-gray-800 text-center mb-8">
            Our Services
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div
                key={index}
                className={`bg-white shadow-lg rounded-lg p-6 transition-transform duration-300 ease-in-out hover:scale-105 ${service.color}`}
              >
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">
                  {service.title}
                </h2>
                <p className="text-gray-700 leading-relaxed">
                  {service.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Services;
