// src/App.js

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import StudentsPage from "./pages/StudentsPage";
import TeachersPage from "./pages/TeachersPage";
import ClassesPage from "./pages/ClassesPage";
import LoginPage from "./pages/LoginPage";
import ContactUsPage from "./pages/ContactUsPage";
import RegisterPage from "./pages/RegisterPage";
import AdminDashboard from "./pages/AdminDashboard";
import ServicesPage from "./pages/ServicesPage.js";

// import RegisterForm from "./components/RegisterForm";
// import LoginForm from "./components/LoginForm";
// import AdminAccess from "./components/AdminAccess";

import "./styles/App.css";
import "./styles/index.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/students" element={<StudentsPage />} />
          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/classes" element={<ClassesPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
      </div>
    </Router>
  );
  // }
  // testing tailwind

  // return (
  //   <div className="text-center">
  //     <header className="bg-blue-500 min-h-screen flex items-center justify-center text-white">
  //       <h1 className="text-6xl">Welcome to Tailwind CSS with React</h1>
  //     </header>
  //   </div>
  // );
}

export default App;
