// src/components/Navbar.js
import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-white shadow-md fixed top-0 w-full z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        {/* Logo */}
        <div className="flex items-center space-x-3">
          <a href="/" className="flex items-center py-2 px-2">
            <img
              src="https://via.placeholder.com/40"
              alt="Logo"
              className="h-8 w-8"
            />
            <span className="font-semibold text-gray-800 text-xl ml-2">
              World Star Academy
            </span>
          </a>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6">
          <a
            href="/"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Home
          </a>
          <a
            href="/about"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            About
          </a>
          <a
            href="/services"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Services
          </a>
          <a
            href="/contact"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Contact
          </a>
          <a
            href="/login"
            className="py-2 px-3 text-gray-700 font-semibold hover:text-green-600 transition duration-300"
          >
            Log In
          </a>
          <a
            href="/register"
            className="py-2 px-3 text-white bg-green-500 rounded hover:bg-green-400 transition duration-300"
          >
            Sign Up
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button className="outline-none p-2" onClick={toggleMenu}>
            {isMenuOpen ? (
              <FaTimes className="text-gray-700 text-2xl" />
            ) : (
              <FaBars className="text-gray-700 text-2xl" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } fixed top-0 left-0 w-full bg-white shadow-lg z-20`}
      >
        <ul className="flex flex-col items-center py-4">
          <li>
            <a
              href="/"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="/about"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              About
            </a>
          </li>
          <li>
            <a
              href="/services"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="/contact"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Contact
            </a>
          </li>
          <li>
            <a
              href="/login"
              className="block text-lg py-4 text-gray-700 font-semibold hover:bg-green-500 hover:text-white transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Log In
            </a>
          </li>
          <li>
            <a
              href="/register"
              className="block text-lg py-4 text-white bg-green-500 rounded hover:bg-green-400 transition duration-300"
              onClick={() => setIsMenuOpen(false)}
            >
              Sign Up
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
