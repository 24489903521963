import React from "react";

function Dashboard() {
  return (
    <div className="dashboard">
      <div className="card">
        <h3>Attendance</h3>
        <p>85%</p>
      </div>
      <div className="card">
        <h3>Performance</h3>
        <p>75%</p>
      </div>
      <div className="card">
        <h3>Announcements</h3>
        <ul>
          <li>School will remain closed on 15th August.</li>
          <li>Parent-Teacher meeting on 20th August.</li>
        </ul>
      </div>
    </div>
  );
}

export default Dashboard;
