// src/pages/HomePage.js
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../components/Navbar";
import AboutUs from "../components/AboutUs";
import Mission from "../components/Mission";
import Vision from "../components/Vision";
import ContactUs from "../components/ContactUs";
import Testimonials from "../components/Testimonials";
import Services from "../components/Services";
import Footer from "../components/Footer";
import "../styles/App.css";

const HomePage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar />

      <header className="relative h-screen main">
        <Carousel
          autoPlay
          infiniteLoop
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          className="absolute inset-0 h-full w-full z-0"
        >
          <div>
            <img src="/images/school_img_1.jpg" alt="Slide 1" />
          </div>
          <div>
            <img src="/images/school_img_2.jpg" alt="Slide 2" />
          </div>
          <div>
            <img src="/images/school_img_3.jpg" alt="Slide 3" />
          </div>
        </Carousel>

        <div className="relative z-10 flex flex-col items-center inset-0 justify-center h-full text-white bg-black bg-opacity-50 home_header">
          <h1 className="text-3xl md:text-5xl font-bold text-black">
            Welcome to Our School
          </h1>
          <p className="mt-4 text-xl">
            Empowering Students for a Bright Future
          </p>
          <button className="mt-8 px-4 py-2 bg-blue-500 rounded-lg">
            Learn More
          </button>
        </div>
      </header>

      <main className="container mx-auto py-8 mt-[20rem] margin">
        {/* Add other sections */}
        <AboutUs />
        <Mission />
        <Vision />
        <Services />
        <Testimonials />
        <ContactUs />
        <Footer />
      </main>

      {/* <footer className="bg-blue-600 text-white p-4">
        <div className="container mx-auto">
          <p>&copy; 2024 Our School. All rights reserved.</p>
        </div>
      </footer> */}
    </div>
  );
};

export default HomePage;
