// src/components/ContactUs.js
import React from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";

const ContactUs = () => {
  return (
    <section
      id="contact"
      className="p-8 bg-gradient-to-r from-gray-50 to-gray-100"
    >
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-8">
          <span className="text-blue-600">Contact </span>
          <span className="text-green-600">Us</span>
        </h2>
        <p className="text-gray-700 mb-6">
          We are here to answer any questions you may have. Please reach out to
          us using the following contact details:
        </p>
        <div className="flex flex-col md:flex-row items-center justify-center gap-6">
          <div className="bg-white p-6 shadow-lg rounded-lg w-full md:w-1/3 text-left">
            <div className="flex items-center mb-4">
              <FaEnvelope className="text-3xl text-blue-500 mr-3" />
              <div>
                <h4 className="text-lg font-semibold text-gray-800">Email</h4>
                <p className="text-gray-600">info@ourschool.com</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg w-full md:w-1/3 text-left">
            <div className="flex items-center mb-4">
              <FaPhone className="text-3xl text-green-500 mr-3" />
              <div>
                <h4 className="text-lg font-semibold text-gray-800">Phone</h4>
                <p className="text-gray-600">(234) 816-550-3480</p>
              </div>
            </div>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg w-full md:w-1/3 text-left">
            <div className="flex items-center mb-4">
              <FaMapMarkerAlt className="text-3xl text-red-500 mr-3" />
              <div>
                <h4 className="text-lg font-semibold text-gray-800">Address</h4>
                <p className="text-gray-600">Eyenkorin, Ilorin, Kwara State</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
