// src/components/Services.js
import React from "react";
import { FaBook, FaRegStar, FaHandshake } from "react-icons/fa";

const Services = () => {
  return (
    <section
      id="services"
      className="p-8 bg-gradient-to-r from-gray-50 to-gray-100"
    >
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
          <span className="text-blue-600">Our </span>
          <span className="text-green-600">Services</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-4">
              <FaBook className="text-4xl text-blue-500" />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Academic Programs
            </h3>
            <p className="text-gray-600">
              We offer a comprehensive range of academic programs from primary
              to secondary education.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-4">
              <FaRegStar className="text-4xl text-yellow-500" />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Extracurricular Activities
            </h3>
            <p className="text-gray-600">
              Our school provides a variety of extracurricular activities
              including sports, arts, and music.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-4">
              <FaHandshake className="text-4xl text-green-500" />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 mb-2">
              Counseling Services
            </h3>
            <p className="text-gray-600">
              We offer counseling services to support the mental and emotional
              well-being of our students.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
