// src/components/Testimonials.js
import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const Testimonials = () => {
  return (
    <section
      id="testimonials"
      className="p-8 bg-gradient-to-r from-gray-50 to-gray-200"
    >
      <div className="max-w-5xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-8">
          <span className="text-blue-600">What Our </span>
          <span className="text-green-600">Parents Say</span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 relative">
            <FaQuoteLeft className="absolute text-5xl text-gray-300 left-4 top-4" />
            <FaQuoteRight className="absolute text-5xl text-gray-300 right-4 bottom-4" />
            <p className="italic text-gray-700 mb-4">
              "The best school ever! My child has improved so much!"
            </p>
            <p className="text-right font-semibold text-gray-800">- Parent 1</p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 relative">
            <FaQuoteLeft className="absolute text-5xl text-gray-300 left-4 top-4" />
            <FaQuoteRight className="absolute text-5xl text-gray-300 right-4 bottom-4" />
            <p className="italic text-gray-700 mb-4">
              "Amazing teachers and facilities. Highly recommended."
            </p>
            <p className="text-right font-semibold text-gray-800">- Parent 2</p>
          </div>
          <div className="bg-white p-6 shadow-lg rounded-lg transition-transform transform hover:scale-105 relative">
            <FaQuoteLeft className="absolute text-5xl text-gray-300 left-4 top-4" />
            <FaQuoteRight className="absolute text-5xl text-gray-300 right-4 bottom-4" />
            <p className="italic text-gray-700 mb-4">
              "My kids love going to school now. Thank you for making education
              fun!"
            </p>
            <p className="text-right font-semibold text-gray-800">- Parent 3</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
