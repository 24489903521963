// src/pages/AdminDashboard.js

import React from "react";
import Navbar from "../components/Navbar";
import Dashboard from "../components/Dashboard";

function AdminDashboard() {
  return (
    <div>
      <Navbar />
      <h1>Admin Dashboard</h1>
      <Dashboard />
    </div>
  );
}

export default AdminDashboard;
