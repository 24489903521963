import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex flex-col md:flex-row md:justify-between">
          {/* About Section */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2">About Us</h2>
            <p className="text-gray-400">
              World Star Academy is dedicated to fostering a nurturing
              environment for students to thrive academically and personally.
              Join us in shaping the future of education.
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-6 md:mb-0">
            <h2 className="text-2xl font-bold mb-2">Quick Links</h2>
            <ul>
              <li>
                <a href="/about" className="text-gray-400 hover:text-white">
                  About Us
                </a>
              </li>
              <li>
                <a href="/programs" className="text-gray-400 hover:text-white">
                  Programs
                </a>
              </li>
              <li>
                <a href="/contact" className="text-gray-400 hover:text-white">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/blog" className="text-gray-400 hover:text-white">
                  Blog
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div>
            <h2 className="text-2xl font-bold mb-2">Contact Us</h2>
            <p className="text-gray-400 mb-2">
              <strong>Email:</strong> info@worldstaracademy.edu
            </p>
            <p className="text-gray-400 mb-2">
              <strong>Phone:</strong> (123) 456-7890
            </p>
            <p className="text-gray-400">
              <strong>Address:</strong> 1234 Academy Lane, Education City, EC
              56789
            </p>
          </div>
        </div>

        {/* Social Media Links */}
        <div className="flex justify-center mt-6">
          <a
            href="https://facebook.com"
            className="text-gray-400 hover:text-white mx-2"
          >
            <FaFacebookF size={20} />
          </a>
          <a
            href="https://twitter.com"
            className="text-gray-400 hover:text-white mx-2"
          >
            <FaTwitter size={20} />
          </a>
          <a
            href="https://instagram.com"
            className="text-gray-400 hover:text-white mx-2"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://linkedin.com"
            className="text-gray-400 hover:text-white mx-2"
          >
            <FaLinkedinIn size={20} />
          </a>
        </div>
      </div>

      <div className="bg-gray-700 text-center py-4">
        <p className="text-gray-400">
          &copy; {new Date().getFullYear()} World Star Academy. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
