// src/components/Vision.js
import React from "react";
import { FaGlobe, FaTrophy } from "react-icons/fa";

const Vision = () => {
  return (
    <section
      id="vision"
      className="p-8 bg-gradient-to-r from-blue-50 to-green-50"
    >
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
          <span className="text-blue-600">Our </span>
          <span className="text-green-600">Vision</span>
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center mb-8">
          <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
            <FaGlobe className="text-4xl text-blue-500 mb-2" />
            <h3 className="text-xl font-semibold text-gray-800">
              Global Awareness
            </h3>
          </div>
          <div className="flex-shrink-0 mb-4 md:mb-0 md:mr-6">
            <FaTrophy className="text-4xl text-green-500 mb-2" />
            <h3 className="text-xl font-semibold text-gray-800">
              Excellence & Success
            </h3>
          </div>
        </div>
        <p className="text-gray-700 mb-4 leading-relaxed">
          Our vision is to be a leading educational institution that sets the
          standard for excellence in education. We aim to cultivate lifelong
          learners who are equipped to adapt and thrive in a rapidly changing
          world.
        </p>
        <p className="text-gray-700 mb-4 leading-relaxed">
          We envision a future where our students are not only academically
          successful but also socially conscious and globally aware.
        </p>
      </div>
    </section>
  );
};

export default Vision;
